body {
  background: no-repeat center fixed url("/public/background-tif.jpg");
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}
.mycontainer {
  margin-top: 40px;
	/* width: 84.2%; */
  width: calc(91% - 100px);
	margin-left: 220px;
}

.dayCell .dayDate {
  text-align: left !important;
}

.suggest {
  position: absolute;
}
.suggest li:hover {
  cursor: pointer;
  background-color: blue;
  color: white;
}

.ttd-height {
  height: 150px;
}

.pointer:hover {
  cursor: pointer;
}

.over-box {
  background-color: #0000009e;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.bg-navbar { 
  background-color: #6c5cfd;
  color: whitesmoke;
  padding: 5px 0;
  position: fixed;
  top: 0;
  z-index: 1;
}
.side-navbar {
  background-color: #3812a1;
  position: fixed;
  left: -200px;
  bottom: 0;
  top: 0;
  color: wheat;
  width: 200px;
  z-index: 2;
  transition: all 0.5s ease-out;
  overflow: hidden;
}
.side-navbar.show { left: 0px;}
.logout-nav {
  margin-bottom: 47px;
}
.side-nav {
  list-style: none;
  padding-left: 0;
}
.side-nav a, .sub-menu a {
  color: white;
}
.side-nav a:focus { 
  background-color: #f1862e;
  color: white; 
}
.side-nav a {
  display: block;
  border-bottom: 1px solid #d3d3d3;
  padding: 0.3rem 1rem 0.3rem 0.5rem;
}
.parent-menu {
  position: relative;
}
.sub-menu {
  padding: 0;
  margin-left: 1px;
  list-style-type: none;
  display: block;
  position: absolute;
  background-color: #3812a1;
  width: 200px;
  top: 0;
  left: 200px;
  visibility: hidden;
}
.sub-menu a {
  text-decoration: none;
  display: block;
  padding: 8px 6px;
}
.parent-menu:hover .sub-menu {
  visibility: visible;
}
.sub-menu.visible {
  visibility: visible;
}
.bg-brown {
  background-color: #a83c3c;
}
.bg-purple, .btn-purple {
  background-color: #b932a0 !important;
}
.close:hover { cursor: pointer; }
.copy-modal {
  position: fixed !important;
  top: 18%;
  left: 20%;
  box-shadow: 0px 5px 40px black;
}

.login-form {
  background-color: #ced4da !important;
}

.login-box-start {
  box-shadow: -3px 3px 20px #9d9d9d;
  border-radius: 30px 0 0 30px;
}

.login-box-end {
  box-shadow: 8px 3px 20px #9d9d9d;
  border-radius: 0 30px 30px 0;
}

.bg-login-cover {
  background-size: cover;
  background-position: center;
}

.footer { font-size: 12px; }

.bg-green-light { background-color: #5ecf5e !important;}

@media only screen and (max-width: 768px) { 
 .img-small { width: 50%; }
  .absolute-col {
    position:absolute; 
    width:10em;
    top:auto;
    left:0;
    border-right: 0px none black;
    background-color: whitesmoke !important;
    border-bottom: 1px solid black !important;
}
  .fix-width{ width: 10em !important; }
  .fix-width-input{ width: 90px !important; }
  .fix-width-select { width: 150px !important; }
  .fix-width-textarea { width: 300px !important; }
}

@media only screen and (max-width: 576px) {
  .bg-login-cover { height: 350px; }
  .bg-navbar { font-size: 12px; }
  .muga-mobile-center {justify-content: center !important;}
  .muga-mobile { flex: 0 0 auto; width: 66.66666667% !important; }
}
@media only screen and (max-width: 380px) {
  .bg-navbar { font-size: 8px; }
}